import p1_img from "./product_1.png";
import p2_img from "./product_2.png";
import p3_img from "./product_3.png";
import p4_img from "./product_4.png";
import p5_img from "./product_5.png";
import p6_img from "./product_6.png";
import p7_img from "./product_7.png";
import p8_img from "./product_8.png";
import p9_img from "./product_9.png";
import p10_img from "./product_10.png";
import p11_img from "./product_11.png";
import p12_img from "./product_12.png";
import p13_img from "./product_13.png";
import p14_img from "./product_14.png";
import p15_img from "./product_15.png";
import p16_img from "./product_16.png";
import p17_img from "./product_17.png";
import p18_img from "./product_18.png";
import p19_img from "./product_19.png";
import p20_img from "./product_20.png";
import p21_img from "./product_21.png";
import p22_img from "./product_22.png";
import p23_img from "./product_23.png";
import p24_img from "./product_24.png";
import p25_img from "./product_25.png";
import p26_img from "./product_26.png";
import p27_img from "./product_27.png";
import p28_img from "./product_28.png";
import p29_img from "./product_29.png";
import p30_img from "./product_30.png";
import p31_img from "./product_31.png";
import p32_img from "./product_32.png";
import p33_img from "./product_33.png";
import p34_img from "./product_34.png";
import p35_img from "./product_35.png";
import p36_img from "./product_36.png";
import p37_img from "./product_37.jpeg";
import p38_img from "./product_38.webp";
import p39_img from "./product_39.jpeg";
import p40_img from "./product_40.jpeg";

let all_product = [
  {
    id: 1,
    name: "Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    category: "women",
    image: p1_img,
    new_price: 50.95,
    old_price: 80.95,
    rating: 4.5,
    reviews: 70,
    lables: [],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 2,
    name: "Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    category: "women",
    image: p2_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4.4,
    reviews: 70,
    lables: ["sale"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 3,
    name: "Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    category: "women",
    image: p3_img,
    new_price: 60.95,
    old_price: 100.95,
    rating: 4.5,
    reviews: 75,
    lables: ["sale"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 4,
    name: "Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    category: "women",
    image: p4_img,
    new_price: 100.95,
    old_price: 150.95,
    rating: 4,
    reviews: 70,
    lables: [],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 5,
    name: "Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    category: "women",
    image: p5_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4.5,
    reviews: 70,
    lables: ["new"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 6,
    name: "Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    category: "women",
    image: p6_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4,
    reviews: 70,
    lables: ["sale"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 7,
    name: "Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    category: "women",
    image: p7_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4,
    reviews: 122,
    lables: ["recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 8,
    name: "Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    category: "women",
    image: p8_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4,
    reviews: 122,
    lables: ["sale", "recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 9,
    name: "Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    category: "women",
    image: p9_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4.8,
    reviews: 106,
    lables: ["new"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 10,
    name: "Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    category: "women",
    image: p10_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 5,
    reviews: 122,
    lables: ["new", "recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 11,
    name: "Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    category: "women",
    image: p11_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 5,
    reviews: 80,
    lables: ["recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 12,
    name: "Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    category: "women",
    image: p12_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 5,
    reviews: 162,
    lables: ["sale"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 13,
    name: "Men Green Solid Zippered Full-Zip Slim Fit Bomber Jacket",
    category: "men",
    image: p13_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 5,
    reviews: 121,
    lables: ["new"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 14,
    name: "Men Green Solid Zippered Full-Zip Slim Fit Bomber Jacket",
    category: "men",
    image: p14_img,
    new_price: 90.95,
    old_price: 120.95,
    rating: 4,
    reviews: 122,
    lables: ["recycled", "sale"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 15,
    name: "Men Green Solid Zippered Full-Zip Slim Fit Bomber Jacket",
    category: "men",
    image: p15_img,
    new_price: 119.99,
    old_price: 120.95,
    rating: 4,
    reviews: 122,
    lables: ["new", "recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 16,
    name: "Men Green Solid Zippered Full-Zip Slim Fit Bomber Jacket",
    category: "men",
    image: p16_img,
    new_price: 45.95,
    old_price: 120.95,
    rating: 4,
    reviews: 178,
    lables: ["new", "recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 17,
    name: "Men Green Solid Zippered Full-Zip Slim Fit Bomber Jacket",
    category: "men",
    image: p17_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4.3,
    reviews: 122,
    lables: ["new", "recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 18,
    name: "Men Green Solid Zippered Full-Zip Slim Fit Bomber Jacket",
    category: "men",
    image: p18_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4.5,
    reviews: 170,
    lables: ["new", "recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 19,
    name: "Men Green Solid Zippered Full-Zip Slim Fit Bomber Hoodie",
    category: "men",
    image: p19_img,
    new_price: 99.95,
    old_price: 120.95,
    rating: 4,
    reviews: 126,
    lables: ["recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 20,
    name: "Men Green Solid Zippered Full-Zip Slim Fit Bomber Hoodie",
    category: "men",
    image: p20_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4,
    reviews: 122,
    lables: [],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 21,
    name: "Men Green Solid Zippered Full-Zip Slim Fit Bomber Jeans",
    category: "men",
    image: p21_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4,
    reviews: 122,
    lables: ["sale", "recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 22,
    name: "Men Green Solid Zippered Full-Zip Slim Fit Bomber Hoodie",
    category: "men",
    image: p22_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4,
    reviews: 122,
    lables: ["sale"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 23,
    name: "Men Green Solid Zippered Full-Zip Slim Fit Bomber Jacket",
    category: "men",
    image: p23_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4,
    reviews: 122,
    lables: ["sale", "recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 24,
    name: "Men Green Solid Zippered Full-Zip Slim Fit Bomber Jacket",
    category: "men",
    image: p24_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4,
    reviews: 122,
    lables: ["new", "recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 25,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "kids",
    image: p25_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4,
    reviews: 122,
    lables: ["new", "recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 26,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "kids",
    image: p26_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4,
    reviews: 122,
    lables: ["new", "sale"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 27,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "kids",
    image: p27_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4,
    reviews: 122,
    lables: ["new", "sale"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 28,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "kids",
    image: p28_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4,
    reviews: 122,
    lables: ["new", "recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 29,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "kids",
    image: p29_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4,
    reviews: 122,
    lables: ["sale", "recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 30,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "kids",
    image: p30_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4,
    reviews: 122,
    lables: ["sale", "recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 31,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "kids",
    image: p31_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4,
    reviews: 122,
    lables: ["sale", "recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 32,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "kids",
    image: p32_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4,
    reviews: 122,
    lables: ["sale"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 33,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "kids",
    image: p33_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4,
    reviews: 122,
    lables: ["sale", "recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 34,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "kids",
    image: p34_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4,
    reviews: 122,
    lables: ["sale", "recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 35,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "kids",
    image: p35_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4,
    reviews: 122,
    lables: ["sale", "recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 36,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "kids",
    image: p36_img,
    new_price: 85.95,
    old_price: 120.95,
    rating: 4,
    reviews: 122,
    lables: ["new", "recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 37,
    name: "Orange Colourblocked Beanie",
    category: "accessories",
    image: p37_img,
    new_price: 15.95,
    old_price: 20.95,
    rating: 4,
    reviews: 17,
    lables: ["sale", "recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 38,
    name: "Blue Colourblocked Toat Bag",
    category: "accessories",
    image: p38_img,
    new_price: 25.95,
    old_price: 30.95,
    rating: 4.5,
    reviews: 80,
    lables: ["new", "recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 39,
    name: "Orange Colourblocked Backpack",
    category: "accessories",
    image: p39_img,
    new_price: 90.95,
    old_price: 130.95,
    rating: 5,
    reviews: 105,
    lables: ["sale"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
  {
    id: 40,
    name: "Wooden Beach Day Sunglasses",
    category: "accessories",
    image: p40_img,
    new_price: 75.95,
    old_price: 100.95,
    rating: 4.5,
    reviews: 129,
    lables: ["new", "recycled"],
    colors: [
      { name: "Wooden Brown", hex: "#a66c29" },
      { name: "Ocean Blue", hex: "#5c89ad" },
      { name: "Forest Green", hex: "#3d8246" },
    ],
  },
];

export default all_product;
